import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import _ from 'cypress/types/lodash';
import parse from '../utils/parse';
import Layout from '../components/layout';
import SEO from '../components/seo';

import './page.scss';

const Page = ({ data }: any) => {
  const {
    page: {
      title, content, seoFields, slug,
    },
  } = data;

  useEffect(() => {
    const scriptNode = document.createElement('SCRIPT');
    // eslint-disable-next-line
    scriptNode.src = 'https://steadyhq.com/widget_loader/a9060c43-a7ab-4cf8-966c-d1125e5ac96a';
    document.body.appendChild(scriptNode);
  }, []);

  const customSeoDescription = seoFields?.customSeoDescription;
  const customSeoTitle = seoFields?.customSeoTitle;
  const [donationAmount, setDonationAmount] = useState(10);
  return (
    <Layout>
      <SEO
        title={customSeoTitle}
        metaTitle={customSeoTitle}
        description={customSeoDescription}
      />
      <article className="article">
        <div className="article-inner">
          <div className="article-wrapper">
            <h2 className="article-title" dir="auto">{title}</h2>
          </div>
          <div className="article-wrapper">
            { slug === 'become-a-member' ? <div id="insert_steady_checkout_here" /> : ''}
            <div className="article-content">{parse(content)}</div>
            {/* Custom donation form */}
            { slug === 'donate' ? (
              <div className="donation-box">
                <div className="donation-input">
                  £
                  <input
                    type="number"
                    value={donationAmount}
                    onChange={(e) => setDonationAmount(+e.target.value)}
                  />
                </div>
                <button
                  type="button"
                  className="product-buy-button snipcart-add-item"
                  data-item-id="DONATION"
                  data-item-price={donationAmount}
                  data-item-url="/donate"
                  data-item-name="Donation to Skin Deep"
                >
                  DONATE
                </button>
              </div>
            ) : ''}
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
query PageById(
  $id: String!
) {
  # selecting the current post by id
  page: wpPage(id: { eq: $id }) {
    slug
    content
    title
    seoFields {
      customSeoDescription
      customSeoTitle
    }
  }
}
`;
